.userlist {
  max-width: 325px;
  width: 100%;
  border-radius: 12px;
  position: absolute;
  top: 63px;
  right: 10px;
}

.userlist header .leaderboard__title {
  position: absolute;
  z-index: 2;
  top: 50%;
  right: calc(var(--start) * 0.75);
  transform: translateY(-50%);
  text-transform: uppercase;
  margin: 0;
}
.userlist header .leaderboard__title span {
  display: block;
}
.userlist header .leaderboard__title--top {
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 6.5px;
}
.userlist header .leaderboard__title--bottom {
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 3.55px;
  opacity: 0.65;
  transform: translateY(-2px);
}
.userlist header .leaderboard__icon {
  fill: #fff;
  opacity: 0.35;
  width: 50px;
  position: absolute;
  top: 50%;
  left: var(--start);
  transform: translate(-50%, -50%);
}
.leaderboard__profiles {
  background-color: #bac8b3;
  border-radius: 12px;
  padding: 10px;
  display: grid;
  row-gap: 6px;
  max-height: calc(100vh - 128px);
  overflow-y: scroll;
}
.leaderboard__profiles::-webkit-scrollbar {
  display: none;
}
.leaderboard__profile {
  display: grid;
  grid-template-columns: 3fr 1fr;
  align-items: center;
  padding: 8px 12px 8px 8px;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 5px 7px -1px rgba(51, 51, 51, 0.23);
  /* cursor: pointer; */
  transition:
    transform 0.25s cubic-bezier(0.7, 0.98, 0.86, 0.98),
    box-shadow 0.25s cubic-bezier(0.7, 0.98, 0.86, 0.98);
  background-color: #fff;
}
.leaderboard__profile:hover {
  transform: scale(1.04);
  box-shadow: 0 9px 47px 11px rgba(51, 51, 51, 0.18);
}
.leaderboard__picture {
  max-width: 100%;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  box-shadow:
    0 0 0 10px #ebeef3,
    0 0 0 22px #f3f4f6;
}
.leaderboard__name {
  color: #979cb0;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.24px;
  margin-left: 12px;
}
.leaderboard__value {
  color: #35d8ac;
  font-weight: 700;
  font-size: 34px;
  text-align: right;
}
.leaderboard__value > span {
  opacity: 0.8;
  font-weight: 600;
  font-size: 13px;
  margin-left: 3px;
}

.button_wrap {
  text-align: right;
  display: flex;
  justify-content: end;
}

.button_wrap > button {
  border-radius: 50px;
  width: 36px;
  height: 36px;
  box-shadow: none;
  border: none;
  background: #437cae;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button_wrap > button > svg {
  width: 19px;
  height: 19px;
}

.userlist {
  box-shadow: 0 0 40px -10px rgba(0, 0, 0, 0.4);
}

.Card {
  padding: 1px;
  border-radius: var(--border-radius);
  background: linear-gradient(
    -67deg,
    rgba(200, 216, 231, 0.7),
    rgba(255, 255, 255, 0.8)
  );
  overflow: hidden;
  box-shadow:
    -2px -2px 6px rgba(255, 255, 255, 0.6),
    2px 2px 12px #c8d8e7;
}

.CardInner {
  padding: 5px 5px;
  background-color: #e2e9f4;
  border-radius: var(--border-radius);
}

.container {
  display: flex;
}

.Icon {
  min-width: 46px;
  min-height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--border-radius);
  margin-left: 12px;
  box-shadow:
    -2px -2px 6px rgba(255, 255, 255, 0.6),
    2px 2px 12px #c8d8e7;
}
.Icon svg {
  transform: translate(-1px, -1px);
}

.InputContainer {
  width: 100%;
}

.SearchBar {
  background-color: #e3edf7;
  padding: 16px 10px;
  border: none;
  display: block;
  font-family: 'Orbitron', sans-serif;
  font-weight: 600;
  color: #a9b8c9;
  transition: all 240ms ease-out;
  width: 100%;
}
.SearchBar::placeholder {
  color: #6d7f8f;
}
.SearchBar:focus {
  outline: none;
  color: #6d7f8f;
  background-color: #eff5fa;
}

.InputContainer {
  --top-shadow: inset 1px 1px 3px #c5d4e3, inset 2px 2px 6px #c5d4e3;
  --bottom-shadow: inset -2px -2px 4px rgba(255, 255, 255, 0.7);
  position: relative;
  border-radius: var(--border-radius);
  overflow: hidden;
}
.InputContainer:before,
.InputContainer:after {
  left: 0;
  top: 0;
  display: block;
  content: '';
  pointer-events: none;
  width: 100%;
  height: 100%;
  position: absolute;
}
.InputContainer:before {
  box-shadow: var(--bottom-shadow);
}
.InputContainer:after {
  box-shadow: var(--top-shadow);
}

.empty_records {
  margin-left: 5px;
  color: black;
  font-size: 14px;
}
