.uploader_wrap {
  border-color: rgba(0, 0, 0, 0.2) !important;
  border-style: dashed !important;
  border-width: 3px !important;
  border-radius: 0.5rem !important;
  width: 100% !important;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: auto !important;
}

.upload_text_1 {
  color: rgb(163 163 163);
  font-weight: 600;
  font-size: 1.875rem;
  line-height: 2.25rem;
  margin-bottom: 1rem;
  font-family: sans-serif;
}

.upload_text_2 {
  color: rgb(163 163 163);
  margin-bottom: 2rem;
  font-family: sans-serif;
  font-weight: 500;
}

.upload_text_3 {
  color: rgb(163 163 163);
  margin-bottom: 1rem;
  font-family: sans-serif;
}

.upload_button {
  color: white;
  background-color: black;
  font-weight: 600;
  font-size: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  border-radius: 99px;
  height: 56px;
  display: flex;
  align-items: center;
  line-height: 1.45;
  letter-spacing: -0.01em;
  font-family: sans-serif;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-shadow-colored: 0px 16px 32px var(--tw-shadow-color);
  --tw-shadow-color: rgba(0, 0, 0, 0.2);
  --tw-shadow: var(--tw-shadow-colored);
  transition-property:
    color,
    background-color,
    border-color,
    text-decoration-color,
    fill,
    stroke,
    opacity,
    box-shadow,
    transform,
    filter,
    backdrop-filter,
    -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0, 0.2, 0.2, 1);
  transition-duration: 0.3s;
  cursor: pointer;
}

.upload_button:hover {
  transform: translate(0, 0) rotate(0) skewX(0) skewY(0) scaleX(1.1) scaleY(1.1);
}

.media_container {
  width: 100%;
  height: calc(100vh - 200px);
}

.upload_button_wrap {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-top: 10px;
  justify-content: center;
}

.delete_button {
  background: white;
  color: black;
}

.upload_error_text {
  color: rgb(182, 42, 42);
  font-size: 18px;
  font-weight: 800;
  margin-bottom: 20px;
}

.uploader_content {
  display: flex;
  justify-content: space-around;
}

.upload_media_details {
  min-width: 200px;
  border: 2px solid #d9d9d9;
  border-radius: 4px;
  padding: 10px 40px;
  width: 50%;
  margin: 0px 20px;
  max-width: 500px;
}

.upload_media_title {
  font-size: 18px;
  color: black;
  font-weight: 800;
  text-align: center;
  margin-bottom: 20px;
}

.upload_details_label {
  font-size: 14px;
  color: black;
  margin-bottom: 2px;
  font-weight: 600;
}

.upload_details_field {
  background-color: transparent;
  color: black;
  border: 1px solid black;
  padding: 10px 5px;
  border-radius: 4px;
  margin-bottom: 10px;
  resize: none;
  width: 100%;
}
