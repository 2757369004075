.menu_wrap {
  display: flex;
  align-items: center;
  display: flex;
  gap: 2px;
}

.button_wrap {
  z-index: 999 !important;

  padding: 0.5rem;
  color: white;
  border-color: white;
  border: 2px solid;
  border-radius: 50px;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;

  justify-content: space-around;
  cursor: pointer;
  position: absolute;
  bottom: 124px;
  left: 8px;
}

.button_wrap svg {
  width: 26px;
  height: 26px;
}

.button_wrap:hover {
  background-color: hsla(0, 0%, 100%, 0.25);
}

.button_wrap:hover svg {
  -webkit-animation: jello-horizontal 0.9s both;
  animation: jello-horizontal 0.9s both;
}

.kbd_key {
  width: 24px;
  height: 24px;
}

.button_group {
  margin: 10px 0px;
}

.video_wrap {
  filter: invert(1);
  width: 32px;
  transform: scale(1.2);
}

.interaction_wrap {
  border-radius: 45px;
  padding: 0px 12px 2px 12px;
  gap: 16px;
}

.interaction_wrap > div {
  font-size: 24px;
  padding: 0rem;
  cursor: pointer;
}

.interaction_wrap > div:hover {
  background: transparent;
}

@-webkit-keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
