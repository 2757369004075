.card {
  background: #000000bd;
  border-radius: 4px;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.5);
  max-width: 400px;
  display: flex;
  flex-direction: row;
  border-radius: 25px;
  position: absolute;
  z-index: 9999 !important;
  top: 8px;
  left: 8px;
}

.close_icon {
  position: absolute;
  top: 10px;
  right: 10px;
}

.close_icon svg {
  width: 20px;
  height: 20px;
}

.card h2 {
  margin: 0;
  padding: 0 1rem;
}

.card .title {
  padding: 1rem 1rem 10px 1rem;
  color: rgb(252, 255, 67);
  font-weight: bold;
  font-size: 12px;
}

.card h3 {
  color: white;
}

.card .name {
  padding: 0.5rem 1rem 5px 1rem;
  color: white;
}

.card .desc {
  padding: 5px 1rem 0.5rem 1rem;
  /* font-size: 13px; */
  color: white;
}

.card .actions {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  padding: 0.5rem 1rem;
}

/* .card svg {
  width: 85px;
  height: 85px;
  margin: 0 auto;
} */

.img_avatar {
  width: 80px;
  height: 80px;
  position: absolute;
  border-radius: 50%;
  border: 6px solid white;
  background-image: linear-gradient(-60deg, #16a085 0%, #f4d03f 100%);
  top: 15px;
  left: 85px;
}

.card_text {
  display: grid;
  grid-template-columns: 1fr 2fr;
  min-width: 200px;
}

.title_total {
  padding: 1em 1em 1em 0em;
  position: relative;
}

.card path {
  fill: white;
}

.img_portada {
  width: 100%;
}

.portada {
  margin: 5px;
  width: 100%;
  height: 100%;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  background-image: url('../../../assets/amity_logo.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.card button {
  border: none;
  background: none;
  font-size: 24px;
  color: #8bc34a;
  cursor: pointer;
  transition: 0.5s;
}
.card button:hover {
  color: #4caf50;
  transform: rotate(22deg);
}
