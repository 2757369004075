.buttons_wrap {
  display: flex;
  color: white;
  gap: 0.5rem;
}

.buttons_wrap > div {
  display: flex;
  align-items: center;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  line-height: 1.45;
  letter-spacing: -0.01em;
  font-size: 0.875rem;
  gap: 0.25rem;
  font-family: sans-serif;
  font-feature-settings:
    'lnum' on,
    'pnum' on;
}

.large_buttons > div {
  display: flex;
  align-items: center;
  -moz-column-gap: 4px;
  column-gap: 4px;
  font-family: sans-serif !important;
  font-size: 0.875rem;
  font-weight: 300;
  color: #fff;
  pointer-events: all;
  font-family: sans-serif;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1rem;
  letter-spacing: 0.01em;
  color: #fff;
}

.large_buttons svg {
  width: 18px !important;
  height: 18px;
}

.eye_icon > svg {
  width: 1rem;
}

.love_icon > svg {
  width: 16px;
}
