.modal_wrap {
  position: absolute;
  top: 0;
  color: black;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.14s cubic-bezier(0, 0.01, 0.35, 0.99);
  z-index: 99999;
}

.modal_data {
  display: flex;
  height: 100%;
}

.left_section {
  width: 230px;
  border-right: 2px solid hsl(0 0% 90%);
  margin-right: 20px;
  padding: 24px;
}

.left_header {
  font-size: 1.75rem;
  font-weight: 900;
  line-height: 1.3;
  letter-spacing: 0.01em;
  font-family: sans-serif;
  font-feature-settings:
    'lnum' on,
    'pnum' on;
  font-family: sans-serif;
  border-bottom: 1px solid hsl(0 0% 90%);
  padding-bottom: 16px;
}

.menu_itmes {
  margin: 16px 0px;
  display: flex;
  flex-direction: column;
  gap: 0.375rem;
}

.menu_itmes > div {
  font-weight: 600;
  color: rgb(115 115 115);
  font-size: 1.125rem !important;
  line-height: 1.45;
  letter-spacing: -0.01em;
  padding: 0.375rem 0px;
  cursor: pointer;
}

.menu_itmes > div:hover {
  color: black;
}

.selected_item {
  color: black !important;
}

.right_section {
  margin-left: 24px;
  padding: 48px;
  width: 100%;
}
