.steps_content {
  max-width: 90vw;
  width: 450px;
  align-items: center;
  text-align: center;
  z-index: 9999;
}

.action_button {
  display: flex;
  justify-content: space-between;
}

.action_button > div {
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.action_button > div:hover > svg {
  color: #534e4e;
}

.action_button svg {
  width: 28px;
  height: 28px;
}

.title {
  font-weight: 600;
  line-height: 1.3;
  letter-spacing: 0.01em;
  font-size: 1.5rem;
  color: var(--base-text-color);
  margin-bottom: 10px;
}

/* .steps_content form {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  align-items: center;
} */

.submit_button {
  height: 36px;
  padding: 0px 12px;
  width: 120px;
  border-radius: 40px;
  border: 0px;
  color: #ffffff;
  background-color: var(--base-bg-color);
  font-weight: 600;
  font-size: 16px;
  letter-spacing: -0.16px;
  cursor: pointer;
  margin: 20px 0px 20px 0px;
}

.submit_button:hover {
  transform: translate(0, 0) rotate(0) skewX(0) skewY(0) scaleX(1.1) scaleY(1.1);
}

.input_field {
  width: 96%;
  background: white;
  border: 0px;
  font-size: 18px;
  border-bottom: 3px solid #00abff;
  color: black;
  text-align: center;
  margin-top: 3rem;
}

.input_field:focus-visible {
  outline: 0px;
}

.reset_password_link {
  font-weight: 600;
  font-size: 16px;
  color: black;
  text-decoration: underline;
  letter-spacing: -0.16px;
  margin-top: 30px;
  cursor: pointer;
}

.info {
  margin-top: 20px;
}

.text {
  font-size: 16px;
  margin: 20px;
  font-family: sans-serif;
  line-height: 24px;
  font-weight: 500;
}

.extra_width {
  width: auto;
  margin-bottom: 20px;
}

/* new css */
.form__group {
  position: relative;
  padding: 15px 0 0;
  margin-top: 10px;
  /* width: 50%; */
  margin: 0px 20px;
}

.form__field {
  font-family: inherit;
  width: 100%;
  border: 0;
  border-bottom: 2px solid var(--base-text-color);
  outline: 0;
  font-size: 19px;
  color: black;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;
}
.form__field::placeholder {
  color: transparent;
}
.form__field:placeholder-shown ~ .form__label {
  font-size: 18px;
  cursor: text;
  top: 25px;
}

.form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 15px;
  color: var(--base-text-color);
}

.form__field:focus {
  padding-bottom: 6px;
  font-weight: 500;
  border-width: 3px;
  border-image: linear-gradient(to right, black, black);
  border-image-slice: 1;
}
.form__field:focus ~ .form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 15px;
  color: black;
  font-weight: 700;
}

/* reset input */
.form__field:required,
.form__field:invalid {
  box-shadow: none;
}

.error {
  color: var(--base-error-color);
  font-weight: 500;
  margin-top: 2px;
}

.link_text {
  color: var(--base-link-color);
  text-decoration: underline;
  font-weight: 500;
  cursor: pointer;
}

.extra_space {
  margin-top: 10px;
}
