.content_wrap {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 2.5rem;
  color: white;
}

.left_section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 632px;
  color: white;
}

.details_wrap {
  gap: 0.375rem;
  display: flex;
  flex-direction: column;
  color: white;
}

.user_details {
  display: flex;
  align-items: center;
  color: white;
}

.sub_title {
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.3;
  letter-spacing: 0.01em;
  font-family: sans-serif;
  font-feature-settings:
    'lnum' on,
    'pnum' on;
  margin-left: 8px;
  margin-top: -2px;
}

.title {
  font-size: 3rem;
  line-height: 1.08;
  letter-spacing: 0.01em;
  font-weight: 900;
}

.description {
  font-size: 14px;
  font-weight: 600;
}

.right_section {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.right_section > div {
  position: relative;
}

.second_avatar {
  margin-right: 0.25rem;
}

.community_guidelines {
  font-size: 12px;
  font-family: sans-serif;
  color: hsla(0, 0%, 100%, 0.5);
  margin-left: 4px;
  margin-top: -2px;
}

.wsad_keys {
  display: grid;
  grid-template-columns: repeat(3, 2rem);
  grid-template-rows: auto auto;
  grid-template-areas:
    '. w .'
    'a s d';
  justify-content: center;
  -moz-column-gap: 0.375rem;
  column-gap: 0.375rem;
  row-gap: 0.375rem;
}

.w_key {
  grid-area: w;
}

.a_key {
  grid-area: a;
}

.s_key {
  grid-area: s;
}

.d_key {
  grid-area: d;
}

.kbd_button {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  font-weight: 700;
  font-family: sans-serif;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border: 1px solid;
  background-color: transparent;
  border-color: hsla(0, 0%, 100%, 0.3);
  border-width: 1px;
  border-bottom-width: 3px;
  border-radius: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-color: rgba(255, 255, 255, 0.3);
}

.plus_icon {
  width: 1em;
  height: 1em;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  font-size: 2.1875rem;
}

.kbd_text {
  font-size: 0.875rem;
  line-height: 1.45;
  letter-spacing: -0.01em;
  position: absolute;
}

.space_btn {
  margin-left: 1.75rem;
}

.text1 {
  left: 37px;
  bottom: -35px;
}

.text2 {
  left: -17px;
  width: 109px;
  bottom: -54px;
}

.text3 {
  left: 34px;
  bottom: -54px;
}

.link_text {
  color: white;
  text-decoration: underline;
  cursor: pointer;
}

.track {
  position: relative;
  width: 100%;
  height: 4px;
  background-color: hsla(0, 0%, 100%, 0.2);
  animation: progress-bar_backAndForth__BUQgC 5s infinite;
}
.handle {
  position: absolute;
  inset: 0;
  background-color: #fff;
  transform: scaleX(var(--progress));
  transform-origin: left;
  transition: transform 0.5s;
}

.btn2 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.btn3 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
