.kbd_button {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  font-weight: 700;
  font-family: sans-serif;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border: 1px solid;
  background-color: transparent;
  border-color: hsla(0, 0%, 100%, 0.3);
  border-width: 1px;
  border-bottom-width: 3px;
  border-radius: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-color: rgba(255, 255, 255, 0.3);
}
