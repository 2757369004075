.custom_modal {
  border-radius: 12px;
  color: black;
  padding: 20px 30px 20px 30px;
  overflow-y: scroll;
  max-height: calc(100vh - 38px);
  vertical-align: bottom;
  background-color: #ffcc04ab;
}

.root {
  background: transparent;
}

.login_options {
  padding: 20px 30px;
}

.custom_modal::-webkit-scrollbar {
  display: none;
}

.img_wrap {
  position: absolute;
  left: 20px;
  top: 16px;
}

.img_wrap > img {
  width: 20px;
  height: 20px;
}

.img_wrap > svg {
  width: 20px;
  height: 20px;
}

.text_wrap {
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.45;
  letter-spacing: -0.01em;
  font-feature-settings:
    'lnum' on,
    'pnum' on;
}

.content_wrap {
  width: 310px;
  transition-property:
    color,
    background-color,
    border-color,
    text-decoration-color,
    fill,
    stroke,
    opacity,
    box-shadow,
    transform,
    filter,
    backdrop-filter,
    -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0, 0.2, 0.2, 1);
  transition-duration: 0.3s;
  padding-left: 2rem;
  padding-right: 2rem;
  background-color: transparent;
  border: 2px solid rgba(0, 0, 0, 0.2);
  position: relative;
  border-radius: 6.25rem;
  margin-bottom: 1rem;
  height: 56px;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
}

.content_wrap:hover {
  transform: translate(0, 0) rotate(0) skewX(0) skewY(0) scaleX(1.1) scaleY(1.1);
}

.logo_container {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.logo_container > svg {
  width: 106px;
  height: 106px;
}
