.modal_wrap {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal_content {
  background: white;
  min-width: 800px;
  width: 90%;
  border-radius: 16px;
  height: 90%;
  /* padding: 40px 32px; */
  /* position: relative; */
  top: 10px;
  animation: showModal 1s forwards;
  position: relative;
}
@keyframes showModal {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
.close_icon {
  --tw-drop-shadow: drop-shadow(0 10px 8px rgba(0, 0, 0, 0.04))
    drop-shadow(0 4px 3px rgba(0, 0, 0, 0.1));
  filter: var(--tw-drop-shadow);
  background: white;
  width: 2rem;
  height: 2rem;
  border-radius: 999px;
  display: flex;
  align-items: center;
  position: absolute;
  top: -15px;
  right: -13px;
  justify-content: center;
  cursor: pointer;
}
