.avaturn_sdk_container {
  /* height: calc(100vh - 41px);
      width: calc(100% - 41px);
      max-height: 800px; */
  height: 100%;
  width: 100%;
  border: none;
  /* margin: 20px; */
  position: absolute;
  z-index: 99;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

/* Animate Background Image */
@keyframes aitf {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

.avaturn_sdk_container:has(iframe)::before {
  content: 'AVATAR GENERATION';
  /* properties */
  font:
    700 1.4em/1 'Oswald',
    sans-serif;
  letter-spacing: 0;
  padding: 0.25em 0 0.325em;
  display: block;
  margin: 0 auto;
  text-shadow: 0 0 80px rgba(255, 255, 255, 0.5);
  /* Clip Background Image */
  background: url(https://i.ibb.co/RDTnNrT/animated-text-fill.png) repeat-y;
  -webkit-background-clip: text;
  background-clip: text;
  /* Animate Background Image */
  -webkit-text-fill-color: transparent;
  -webkit-animation: aitf 80s linear infinite;
  /* Activate hardware acceleration for smoother animations */
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
}

.iframe_wrap {
  max-width: 80%;
  max-height: 80%;
  width: 100%;
  height: 100%;
}
