.main {
  position: relative;
  width: 100%;
}

.loading_bg_img {
  width: 100%;
  height: 100vh;
  filter: blur(10px);
}

.loader_wrap {
  overflow-y: hidden;
  position: absolute;
  width: 100%;
  height: 100vh;
  z-index: 2;
  background-color: black;
}

.loading_details {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.env_content {
  width: 100%;
  height: 100vh;
  background: white;
  /* margin: 20px; */
  /* opacity: 1; */
  transition: all 1s;
  position: absolute;
}

@keyframes flash {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.bottom_bar {
  display: flex;
  position: absolute;
  bottom: 0px;
  justify-content: space-between;
  width: 100%;
  padding: 8px 8px;
  align-items: end;
}

.bottom_bar {
  display: flex;
  position: absolute;
  bottom: 0px;
  justify-content: space-between;
  width: 100%;
  padding: 8px 8px;
  align-items: end;
}

.video_wrap {
  width: 100%;
  height: auto;
}
