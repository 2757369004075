.custom_modal {
  border-radius: 12px;
  color: white;
  padding: 48px;
  overflow-y: scroll;
  max-height: calc(100vh - 38px);
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(64px);
  max-width: 450px;
  align-items: center;
}

.custom_modal::-webkit-scrollbar {
  display: none;
}

.title {
  font-weight: 600;
  line-height: 1.3;
  letter-spacing: 0.01em;
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 30px;
}

.modal_overlay {
  background: transparent;
}

.label_wrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
}

.label {
  font-weight: 700;
  line-height: 1.45;
  letter-spacing: -0.01em;
  font-size: 0.875rem;
}

.generate_btn {
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.45;
  letter-spacing: -0.01em;
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
}

.checkbox_label {
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.45;
  letter-spacing: -0.01em;
}

.generate_btn svg {
  width: 16px;
  height: 16px;
}
.input_field {
  width: 100%;
  background: transparent;
  border: 0px;
  font-size: 14px;
  border: 1px solid;
  color: white;
  border-color: hsla(0, 0%, 100%, 0.25);
  height: 3rem;
  border-radius: 8px;
  padding: 12px 16px;
}

.input_field:focus-visible {
  outline: 0px;
}

.checkbox_wrap {
  display: flex;
  gap: 4px;
  align-items: center;
  margin-top: 6px;
  margin-bottom: 30px;
}

.checkbox_field {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.checkbox_wrap input[type='checkbox']:checked::before {
  /* transform: scale(1); */
}

.checkbox_wrap input[type='checkbox'] {
  position: absolute;
  opacity: 0;
  /* z-index: -1; */
}

/* Checkbox un-checked style */
.checkbox_wrap input[type='checkbox'] + span:before {
  content: '';
  cursor: pointer;

  border: 1px solid white;
  border-radius: 4px;
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 0.5em;
  margin-top: 0.5em;
  vertical-align: -2px;
}

/* Checked checkbox style (in this case the background is green #e7ffba, change this to change the color) */
.checkbox_wrap input[type='checkbox']:checked + span:before {
  /* NOTE: Replace the url with a path to an SVG of a checkmark to get a checkmark icon */
  background-image: url('https://cdnjs.cloudflare.com/ajax/libs/ionicons/4.5.6/collection/build/ionicons/svg/ios-checkmark.svg');
  background-repeat: no-repeat;
  background-position: center;
  /* The size of the checkmark icon, you may/may not need this */
  background-size: 25px;
  border-radius: 2px;
  background-color: white;
  color: white;
}

/* Adding a dotted border around the active tabbed-into checkbox */
.checkbox_wrap input[type='checkbox']:focus + span:before,
.checkbox_wrap input[type='checkbox']:not(:disabled) + span:hover:before {
  /* Visible in the full-color space */
  /* box-shadow: 0px 0px 0px 2px rgba(0, 150, 255, 1); */

  /* Visible in Windows high-contrast themes
     box-shadow will be hidden in these modes and
     transparency will not be hidden in high-contrast
     thus box-shadow will not show but the outline will
     providing accessibility */
  outline-color: transparent; /*switch to transparent*/
  outline-width: 0px;
  outline-style: dotted;
}

/* Disabled checkbox styles */
.checkbox_wrap input[type='checkbox']:disabled + span {
  cursor: default;
  color: black;
  opacity: 0.5;
}

@keyframes spin {
  100% {
    transform: rotate(1turn);
  }
}

.fetch_icon {
  animation: spin 1s linear infinite;
}

.display_name_wrap {
  margin-bottom: 30px;
}

.submit_btn {
  height: 40px;
  width: 100%;
  border-radius: 30px;
  border: 0px;
  color: black;
  background: white;
  font-weight: 700;
  cursor: pointer;
}

.submit_btn:disabled,
.submit_btn[disabled] {
  background-color: gray;
}

.terms_checkbox input[type='checkbox'] + span:before {
  margin-bottom: 6px;
}

.link_text {
  font-weight: 600;
  /* font-size: 16px; */
  color: white;
  text-decoration: underline;
  letter-spacing: -0.16px;
  margin-top: 30px;
  cursor: pointer;
}

.error_text {
  color: rgb(202, 41, 41);
  font-size: 13px;
  width: 100%;
}
