body {
  --base-text-color: #000000;
  --base-bg-color: #000000;
  --base-link-color: #4581b6;
  --base-error-color: rgb(224, 44, 44);
  --base-btn-bg-color: white;
}

body::-webkit-scrollbar {
  display: none;
}
