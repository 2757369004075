.menu_wrap {
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 999 !important;
}

.side_menu_wrap {
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 64px;
  z-index: 9999 !important;
  right: 16px;
  border-radius: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px 1.25rem;
  width: 298px;
  color: white;
  backdrop-filter: blur(4px);
  border: 1px solid hsla(0, 0%, 100%, 0.1);
  max-height: calc(100vh - 78px);
  overflow-y: auto;
}

/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

/* total width */
.side_menu_wrap::-webkit-scrollbar {
  background-color: transparent;
  width: 8px;
}

/* background of the scrollbar except button or resizer */
.side_menu_wrap::-webkit-scrollbar-track {
  background-color: transparent;
}
.side_menu_wrap::-webkit-scrollbar-track:hover {
  background-color: transparent;
}

/* scrollbar itself */
.side_menu_wrap::-webkit-scrollbar-thumb {
  background-color: white;
  border-radius: 16px;
  border: 4px solid transparent;
  /* margin-right: 10px; */
}
.side_menu_wrap::-webkit-scrollbar-thumb:hover {
  background-color: white;
  border: 4px solid transparent;
}

/* set button(top and bottom of the scrollbar) */
.side_menu_wrap::-webkit-scrollbar-button {
  display: none;
}

.user_details_wrap {
  display: flex;
  justify-content: space-between;
}

.user_details {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.375rem;
  font-size: 0.875rem;
  line-height: 1.3;
  letter-spacing: 0.01em;
  font-weight: 600;
}

.user_details > img {
  border-radius: 999px;
}

.interaction_button {
  display: flex;
  gap: 0.25rem;
}

.interaction_button > div {
  --white: 0 0% 100%;
  border: 2px solid;
  border-color: hsla(0, 0%, 100%, 0.25);
  width: 2rem;
  height: 2rem;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 99px;
  cursor: pointer;
}

.interaction_button > div:hover {
  background-color: hsla(0, 0%, 100%, 0.25);
}

.interaction_button svg {
  width: 1em;
  height: 1em;
}

.env_title {
  line-height: 1.3;
  letter-spacing: 0.01em;
  font-size: 1.75rem;
  font-weight: 600;
}

.env_description {
  cursor: pointer;
  line-height: 20.3px;
  letter-spacing: -0.14px;
  font-size: 14px;
  font-weight: 400;
  transition: 'all .6s';
}

.env_details {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.show_more_button {
  background-color: transparent;
  border: none;
  color: white;
  font-family: sans-serif !important;
  font-size: 13px;
  display: inline-block;
  text-align: left;
  font-weight: 600;
  cursor: pointer;
  transition-property: background-color, color, border-color, letter-spacing;
  transition-timing-function: ease;
  transition-duration: 0.5s;
}

.menu_items {
  border-top: 1px solid hsla(0, 0%, 100%, 0.1);
  padding-top: 8px;
}

.menu_items div {
  line-height: 1.45;
  letter-spacing: -0.01em;
  font-size: 0.875rem;
  text-decoration-line: none;
  font-weight: 500;
  padding: 2px 4px;
  cursor: pointer;
}

.menu_items div:hover {
  background-color: hsla(0, 0%, 100%, 0.25);
  border-radius: 4px;
}

.button_wrap {
  padding: 0.375rem 0.5rem;
  color: white;
  border-color: hsl(0 0% 100%/0.2);
  border: 2px solid;
  border-radius: 0.5rem;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  width: 92px;
  justify-content: space-around;
  cursor: pointer;
}

.button_wrap:hover {
  background-color: hsla(0, 0%, 100%, 0.25);
}

.kbd_key {
  width: 24px;
  height: 24px;
}

.button_group {
  margin: 10px 0px;
}
