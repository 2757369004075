.tooltip_wrap {
  background-color: white;
  color: black;
  padding: 11px 12px;
  font-size: 13px;
  font-weight: 600;
  border-radius: 40px;
}

.tooltip_arrow {
  color: white;
}

.tooltip_arrow polygon {
  fill: white;
}
