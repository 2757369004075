.menu_wrap {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 1.5rem;
  display: flex;
  padding: 0px 2px;
  align-items: center;
  justify-content: center;
}

.menu_wrap > div {
  padding: 0.5rem;
  display: flex;
}

.menu_wrap > div:hover {
  background-color: hsla(0, 0%, 100%, 0.25);
  border-radius: 9999px;
}
