.modal_wrap {
  position: absolute;
  top: 0;
  color: black;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.5); */
  transition: opacity 0.14s cubic-bezier(0, 0.01, 0.35, 0.99);
  z-index: 99999;
}

.content_wrap {
  background-color: transparent;
  backdrop-filter: blur(80px);
  width: fit-content;
}

.media_wrap {
  display: flex;
  gap: 20px;
  padding: 20px 20px;
}

.left_section {
}

.left_section > img {
  object-fit: contain;
  width: auto;
}

.media_wrap {
}

.close_icon {
}

.details {
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: lemonchiffon;
  max-width: 400px;
  align-items: center;
  justify-content: center;
}

.title {
  font-size: 20px;
  font-weight: 800;
}

.link {
  width: 200px;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: black;
  font-weight: 600;
  font-size: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  border-radius: 99px;
  height: 56px;
  display: flex;
  align-items: center;
  line-height: 1.45;
  letter-spacing: -0.01em;
  font-family: sans-serif;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-shadow-colored: 0px 16px 32px var(--tw-shadow-color);
  --tw-shadow-color: rgba(0, 0, 0, 0.2);
  --tw-shadow: var(--tw-shadow-colored);
  transition-property:
    color,
    background-color,
    border-color,
    text-decoration-color,
    fill,
    stroke,
    opacity,
    box-shadow,
    transform,
    filter,
    backdrop-filter,
    -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0, 0.2, 0.2, 1);
  transition-duration: 0.3s;
  cursor: pointer;
}
